import React, { useState } from 'react';
import CertificationModal from '../actions/CertificationModal';
import ArchiveModal from '../actions/ArchiveModal';

const CertificationAction = ({ application, onActionSuccess }) => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] = useState(false);

  const handleOpenArchiveModal = () => {
    setIsArchiveModalOpen(true);
  };

  const handleCloseArchiveModal = () => {
    setIsArchiveModalOpen(false);
  };

  const handleOpenCertificationModal = () => {
    setIsCertificationModalOpen(true);
  };

  const handleCloseCertificationModal = () => {
    setIsCertificationModalOpen(false);
  };

  const name = `${application.student.account.firstName} ${application.student.account.lastName}`;

  const formattedStartDate = !application.startDate ? "" : (
    new Date(application.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
  )

  return (
    <div className="mb-4 p-4 border rounded bg-white shadow-md">
      <div className="text-[0.95rem] mb-3">{name}'s application to {application.program.programName} needs to be certified. Start date: {formattedStartDate}.</div>
      <div className="flex space-x-4">
        <button className="w-full py-1 border border-gray-500 text-gray-500 rounded" onClick={handleOpenArchiveModal}>Archive</button>
        <button className="w-full py-1 bg-fortify-green text-white rounded" onClick={handleOpenCertificationModal}>Certify</button>
      </div>

      <ArchiveModal
        open={isArchiveModalOpen}
        onClose={handleCloseArchiveModal}
        applicationData={application}
        onActionSuccess={onActionSuccess}
      />

      <CertificationModal
        open={isCertificationModalOpen}
        onClose={handleCloseCertificationModal}
        applicationData={application}
        onActionSuccess={onActionSuccess}
      />
    </div>
  );
};

export default CertificationAction;