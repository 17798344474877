import React, { useState } from 'react';
import { Dialog, Button, TextField, Alert, CircularProgress } from '@mui/material';
import { certifyLoanApplication, getMonthlyPayment } from '../../../api/applications';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';
import dayjs from 'dayjs';

const CertificationModal = ({ open, onClose, applicationData, onActionSuccess }) => {
  const [isEditingTuitionRequested, setIsEditingTuitionRequested] = useState(false);
  const [tuitionRequested, setTuitionRequested] = useState(parseFloat(applicationData.tuitionRequest));
  const [lastTuitionRequestForPaymentCalc, setLastTuitionRequestForPaymentCalc] = useState(parseFloat(applicationData.tuitionRequest));

  const [isEditingDownPayment, setIsEditingDownPayment] = useState(false);
  const [downPayment, setDownPayment] = useState(!applicationData.loan.requiredDownPayment ? 0 : parseFloat(applicationData.loan.requiredDownPayment));
  const [lastDownPaymentForPaymentCalc, setLastDownPaymentForPaymentCalc] = useState(parseFloat(!applicationData.loan.requiredDownPayment ? 0 : parseFloat(applicationData.loan.requiredDownPayment)));

  const [monthlyPayment, setMonthlyPayment] = useState(!applicationData.loan.monthlyPayment ? null : parseFloat(applicationData.loan.monthlyPayment));
  const [loanTerm, setLoanTerm] = useState(applicationData.loan.loanTerm);

  const [isPaymentTermsLoading, setIsPaymentTermsLoading] = useState(false);
  const [paymentTermsError, setPaymentTermsError] = useState(null);

  const [startDate, setStartDate] = useState(applicationData.startDate);
  const [gradDate, setGradDate] = useState(applicationData.gradDate);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchMonthlyPayment = async () => {
    setIsEditingTuitionRequested(false);
    setIsEditingDownPayment(false);

    setIsPaymentTermsLoading(true);
    setPaymentTermsError(null);

    setLastTuitionRequestForPaymentCalc(tuitionRequested);
    setLastDownPaymentForPaymentCalc(downPayment);
    try {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD')
      const response = await getMonthlyPayment(applicationData.schoolId, applicationData.id, tuitionRequested, downPayment, formattedStartDate);
      setMonthlyPayment(parseFloat(response.monthly_payment));
      setLoanTerm(response.loan_term);
    } catch (error) {
      logErrorToConsole("Failed to fetch monthly payment", error);
      setPaymentTermsError(error.response.data?.error ? `Error: ${error.response.data?.error}` : 'Please choose a valid tuition request & down payment to see the monthly payment.')

      if (
        error.response?.status === 400 &&
        !!error.response?.data?.error
      ) {
        // don't raise an alert
      } else {
        logErrorToSentryWithContext(error, {
          schoolId: applicationData.schoolId,
          applicationId: applicationData.id,
          tuitionRequested,
          downPayment,
          startDate,
        });
      }
    } finally {
      setIsPaymentTermsLoading(false);
    }
  };

  const handleToggleEditTuitionRequested = () => {
    if (isEditingTuitionRequested) {
      fetchMonthlyPayment();
    } else {
      setIsEditingTuitionRequested(true);
    }
  };

  const handleToggleEditDownPayment = () => {
    if (isEditingDownPayment) {
      fetchMonthlyPayment();
    } else {
      setIsEditingDownPayment(true);
    }
  };

  const handleTuitionChange = async (e) => {
    setError('');
    const value = parseFloat(e.target.value);
    setTuitionRequested(isNaN(value) ? 0 : value);
  };

  const handleDownPaymentChange = async (e) => {
    setError('');
    const value = parseFloat(e.target.value);
    setDownPayment(isNaN(value) ? 0 : value);
  };

  // TODO: i would love to trigger a fetchMonthlyPayment here, but can't figure it out.
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const formatCurrency = (amount) => {
    if (!amount == null) return "";
    return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  const handleCertify = async () => {
    setIsLoading(true);
    setError('');
    try {
      await certifyLoanApplication(applicationData.schoolId, applicationData.id, {
        decision: "approved",
        tuition_request: tuitionRequested,
        down_payment: downPayment,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        grad_date: dayjs(gradDate).format('YYYY-MM-DD'),
      });
      onClose();
      onActionSuccess();
    } catch (error) {
      logErrorToConsole("Error certifying loan application", error);
      setError(error.response.data?.error || 'Failed to certify loan application');

      if (
        error.response?.status === 400 &&
        !!error.response?.data?.error
      ) {
        // don't raise an alert
      } else {
        logErrorToSentryWithContext(error, {
          schoolId: applicationData.schoolId,
          applicationId: applicationData.id,
          tuitionRequested,
          downPayment,
          startDate,
          gradDate
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const tuitionFinanced = tuitionRequested - downPayment;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className='px-6 py-10'>
        <div className='mx-12'>
          <h1 className="text-2xl mb-6 font-lora"><b>Certify {applicationData.student.account.firstName} {applicationData.student.account.lastName}'s application</b></h1>

          <div className="mb-8">
            <h1 className="text-lg mb-2 font-lora"><b>Program</b></h1>
            <p className="mb-1">{applicationData.program.programName}</p>
            <p className="text-gray-500 text-sm">Need to adjust the program they're attending? Please <a href="mailto:help@fortifyedu.com" className="underline">contact us</a>.</p>
          </div>

          <div className="mb-10">
            <h1 className="text-lg mb-2 font-lora"><b>Loan Terms</b></h1>

            <div className="flex justify-between items-center mb-2">
              <div>Tuition Requested</div>
              <div className="flex items-center space-x-4">
                {isEditingTuitionRequested ? (
                  <TextField
                    className="max-w-[100px]"
                    value={tuitionRequested}
                    onChange={handleTuitionChange}
                    size="small"
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                ) : (
                  <div>{formatCurrency(tuitionRequested)}</div>
                )}
                <div onClick={handleToggleEditTuitionRequested}>
                  <img
                    className="w-5 h-5"
                    src={isEditingTuitionRequested ? "/certificationModal/save.svg" : "/certificationModal/edit.svg"}
                    alt="Edit Icon"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center mb-2">
              <div>Down Payment (through Fortify)</div>
              <div className="flex items-center space-x-4">
                {isEditingDownPayment ? (
                  <TextField
                    className="max-w-[100px]"
                    value={downPayment}
                    onChange={handleDownPaymentChange}
                    size="small"
                    inputProps={{ style: { textAlign: 'right' } }}
                  />
                ) : (
                  <div>-{formatCurrency(downPayment)}</div>
                )}
                <div onClick={handleToggleEditDownPayment}>
                  <img
                    className="w-5 h-5"
                    src={isEditingDownPayment ? "/certificationModal/save.svg" : "/certificationModal/edit.svg"}
                    alt="Edit Icon"
                  />
                </div>
              </div>
            </div>

            <hr className="my-2" />

            <div className="flex justify-between items-center">
              <div>Tuition Financed</div>
              <div className="flex items-center space-x-4">
                <div>{formatCurrency(tuitionFinanced)}</div>
                <div className="w-5 h-5"></div>
              </div>
            </div>

          </div>

          <div className="mb-10">
            <h1 className="text-lg mb-4 font-lora"><b>Enrollment Dates</b></h1>
            <div className="flex justify-between space-x-8">
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <TextField
                label="Grad Date"
                type="date"
                value={gradDate}
                onChange={(e) => setGradDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </div>
          </div>
          
          <div className="mb-10 p-4 border rounded bg-gray-100 mx-auto">
            {isPaymentTermsLoading ? (
              <div className="flex justify-center items-center py-4 px-4 h-full">
                <CircularProgress size={15}/>
              </div>
            ) : (
              <>
                {paymentTermsError ? (
                  <div>{paymentTermsError}</div>
                ) : (
                  <>
                    Based on a {formatCurrency(lastTuitionRequestForPaymentCalc)} tuition request and a {formatCurrency(lastDownPaymentForPaymentCalc)} down payment, {applicationData.student.account.firstName}'s payments will be <b>{formatCurrency(monthlyPayment)}</b>/month for <b>{loanTerm} months</b>.
                  </>
                )}
              </>
            )}
          </div>
          {error && <Alert severity="error" style={{ marginBottom: '2rem' }}>{error}</Alert>}
          {(isEditingDownPayment || isEditingTuitionRequested) && <p className='text-legal-gray text-sm mb-2'>Please save your changes above before you certify this application.</p>}
          <div className="flex justify-center">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div className="flex justify-between space-x-4 w-full">
                <Button className="flex-grow" variant="outlined" color="primary" onClick={onClose} sx={{ color: 'fortify-green', borderColor: 'fortify-green' }}>
                  Cancel
                </Button>
                <Button 
                  className="flex-grow"
                  variant="contained"
                  color="primary"
                  sx={{ backgroundColor: 'fortify-green' }}
                  onClick={handleCertify}
                  disabled={paymentTermsError || isEditingTuitionRequested || isEditingDownPayment}
                >
                  Certify
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CertificationModal;