import React from 'react';

const BottomBar = () => {
  return (
    <div className="bg-[#F9F8FA] border-t h-[75px] flex justify-between items-center py-4 px-8 relative">
      <div className="flex space-x-6 text-gray-500">
        <a 
          href="https://fortifyedu.com/privacy" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-sm xl:text-base hover:underline hover:text-gray-700"
        >
          Privacy Policy
        </a>
        <a 
          href="https://fortifyedu.com/tos" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-sm xl:text-base hover:underline hover:text-gray-700"
        >
          Terms of Use
        </a>
      </div>
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <img src="/gray_fortify.svg" alt="Fortify Logo" className="w-6 h-6" />
      </div>
      <div></div>
    </div>
  );
};

export default BottomBar;