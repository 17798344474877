import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Alert, CircularProgress } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/auth';
import FortifyIcon from './FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (emailAddress) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailAddress);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      await login(email, password);

      const user = JSON.parse(localStorage.getItem('user'));

      if (user.type !== 'rep') {
        window.location.href = 'https://apply.fortifyedu.com';
        return;
      }

      navigate('/home');
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 401) {
        setLoginError('The email/password was not valid.');
      } else if (error.response && error.response.status === 429) {
        setLoginError('Sorry, you have made too many attempts. Please contact Fortify support.');
      } else {
        logErrorToConsole("Failed to log in", error);
        logErrorToSentryWithContext(error, {
          email,
        });

        setLoginError("Sorry, there was an error logging you in. We're looking into it!");
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-fortify-green">
      <div className="flex flex-col items-center p-8 bg-white rounded-sm shadow-lg w-96">
        <FortifyIcon />
        <h1 className="text-2xl font-bold mt-2 font-lora">Fortify School Login</h1>
        <p className="text-center mt-4">
          Are you a student looking to get a Fortify loan?{' '}
          <a href="https://apply.fortifyedu.com" className="underline">Apply here</a>.
        </p>
        <div className="flex flex-col space-y-4 w-full mt-8">
          <TextField 
            fullWidth 
            label="Email" 
            variant="outlined" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
          <TextField 
            fullWidth 
            className='sentry-mask'
            label="Password" 
            variant="outlined" 
            id="password" 
            type={showPassword ? 'text' : 'password'} 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="flex flex-col space-y-4">
            {loginError && <Alert severity="error" className='mx-auto text-center' style={{ maxWidth: '400px' }}>{loginError}</Alert>}
            {
              isLoading ? (
                <div className='mt-4 w-full text-center'>
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  variant="contained"
                  className='mt-4'
                  fullWidth
                  sx={{ height: '48px' }}
                  onClick={handleLogin}
                  disabled={!isValidEmail(email) || !password}
                >
                  Login
                </Button>
              )
            }
            <p className='text-sm text-legal-gray text-left'>
              Forgot your password? <a href="/request-password-reset" className="underline">Reset it here</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
