import React, { useState, useEffect } from 'react';
import HomeStats from './HomeStats';
import ActionItems from './ActionItems';
import LoanApplicationData from '../../../models/LoanApplicationData';
import { CircularProgress } from '@mui/material';
import { fetchHomepageForSchool } from '../../../api/applications';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const Home = ({ activeSchool, firstName, refreshDataTrigger }) => {
  const [homepageData, setHomepageData] = useState(null);
  const [homepageDataIsLoading, setHomepageDataIsLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('all_time');

  const getPeriodDates = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    
    switch (selectedPeriod) {
      case 'all_time':
        return `2024-06-01_${today.toISOString().split('T')[0]}`;
      case 'current_month':
        return `${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-01_${currentYear}-${(currentMonth + 2).toString().padStart(2, '0')}-01`;
      case 'last_month':
        return `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01_${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-01`;
      default:
        return `2024-06-01_${today.toISOString().split('T')[0]}`;
    }
  };

  const handlePeriodChange = (newPeriod) => {
    setSelectedPeriod(newPeriod);
  };

  const fetchHomepageData = async () => {
    if (activeSchool && activeSchool.id) {
      setHomepageDataIsLoading(true);
      try {
        const period = getPeriodDates();
        const data = await fetchHomepageForSchool(activeSchool.id, period);
        setHomepageData(data);
      } catch (error) {
        logErrorToConsole("Error fetching homepage data", error);

        if (error.response?.status === 401 && error.response?.data?.msg === "Token has expired") {
          console.log("Refresh token was expired. Logging out.");
        } else {
          logErrorToSentryWithContext(error, {
            schoolId: activeSchool.id,
          });
        }
      } finally {
        setHomepageDataIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchHomepageData();
  }, [activeSchool, refreshDataTrigger, selectedPeriod]);

  const { 
    stats: statsData,
    students_to_certify: studentsToCertify
  } = homepageData || {};

  const loanApplications = studentsToCertify ? studentsToCertify.map(result => new LoanApplicationData(result)) : [];
  const sortedLoanApplications = loanApplications.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

  if (homepageDataIsLoading || statsData === undefined || studentsToCertify === undefined) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="page-title">Home</div>
      <hr />
      <div className="mt-6 mb-8 text-xl text-gray-900">
        Welcome, {firstName}! Here are some stats:
      </div>
      <div className="flex-grow flex home-spacing overflow-hidden">
        <div className="flex-grow">
          <HomeStats statsData={statsData} selectedPeriod={selectedPeriod} onPeriodChange={handlePeriodChange} />
        </div>
        <div className="flex-grow flex flex-col">
          <ActionItems applicationsToCertify={sortedLoanApplications} onActionSuccess={fetchHomepageData} />
        </div>
      </div>
    </div>
  );
};

export default Home;