import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTransactionsForSchool } from '../../api/applications';
import { TransactionData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV } from '../../utils/formatters';

const PaymentHistory = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTransactionsForSchool(activeSchool.id);
        const formattedData = data.map(item => new TransactionData(item));
        const sortedData = formattedData.sort((a, b) => b.date - a.date);

        setTransactionData(sortedData);
      } catch (error) {
        console.error("Error fetching transaction data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.is_admin) {
      navigate('/home');
    } else {
      fetchData();
    }
  }, [activeSchool.id]);

  const exportToCSV = () => {
    const headers = [
      "Transaction Date",
      "Student",
      "Program",
      "Type",
      "Amount",
      "Principal",
      "Interest",
      "Late Charges",
      "Collection/Recovery Fees",
      "Fortify Platform Fee",
      "Fortify Portfolio Management Fee",
      "School Tuition Received",
      "Disbursement Date",
    ];
  
    const csvData = transactionData.map(item => [
      formatDateForCSV(item.date),
      item.studentName,
      item.programName,
      item.type,
      formatCurrencyForCSV(item.amount),
      formatCurrencyForCSV(item.principal),
      formatCurrencyForCSV(item.interest),
      formatCurrencyForCSV(item.lateCharges),
      formatCurrencyForCSV(item.colRcFees),
      formatCurrencyForCSV(item.fortifyPlatformFeeReceived),
      formatCurrencyForCSV(item.fortifyPortfolioManagementFeeReceived),
      formatCurrencyForCSV(item.schoolTuitionReceived),
      formatDateForCSV(item.disbursementDate),
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Payment History ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (transactionData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Payment History</div>
        <hr />
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              You don't have any payments yet.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Payment History</div>
        <button
          onClick={exportToCSV}
          className="bg-fortify-green hover:bg-fortify-green-dark text-white font-semibold py-2 px-4 rounded flex items-center"
        >
          Export to CSV
          <img src="/white_download_icon.svg" alt="Download CSV" className="w-5 h-5 ml-2" />
        </button>
      </div>
      <hr />
      <div className="flex-grow overflow-hidden">
        <div className="overflow-x-auto overflow-y-auto h-full">
          <table className="w-full table-auto border border-gray-300">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Transaction Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Student</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Program</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Type</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Amount</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Principal</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Interest</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Late Charges</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Collection/Recovery Fees</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Fortify Platform Fee</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Fortify Portfolio Management Fee</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">School Tuition Received</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Disbursement Date</th>
              </tr>
            </thead>
            <tbody>
              {transactionData.map((item, index) => (
                <tr key={index} className="border-b h-12">
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.date)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{item.type}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.amount)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.principal)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.interest)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.lateCharges)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.colRcFees)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPortfolioManagementFeeReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.schoolTuitionReceived)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.disbursementDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;