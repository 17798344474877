import React from 'react';

const AccountSettings = ({ user }) => {
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return ""
    }

    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  return (
    <div className="w-full max-w-4xl">
      <h1 className="page-title mb-8">Account Settings</h1>

      <div className="flex items-center mb-8">
        <img src="/person.svg" alt="Profile" className="w-10 h-10 rounded-full mr-6" />
        <div>
          <div className="text-xl font-bold">{user.firstName} {user.lastName}</div>
          <div className="text-gray-500 text-sm">{user.email}</div>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex space-x-6">
          <div className="flex-grow">
            <label className="block text-gray-700">First Name</label>
            <input type="text" value={user.firstName} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
          <div className="flex-grow">
            <label className="block text-gray-700">Last Name</label>
            <input type="text" value={user.lastName} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
        </div>

        <div className="flex space-x-6">
          <div className="flex-grow">
            <label className="block text-gray-700">Email</label>
            <input type="email" value={user.email} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
          <div className="flex-grow">
            <label className="block text-gray-700">Phone Number</label>
            <input type="text" value={formatPhoneNumber(user.phone)} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
        </div>

      </div>

    </div>
  );
};

export default AccountSettings;
