import React from 'react';

const StatusPill = ({ text, backgroundColor }) => {
  return (
    <span className={`${backgroundColor} text-white px-2 py-1 rounded-full text-xs font-semibold`}>
      {text}
    </span>
  );
};

export default StatusPill;