import React from 'react';
import CertificationAction from './CertificationAction';

const ActionItems = ({ applicationsToCertify, onActionSuccess }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="home-page-section-title">Action Items</div>
      <div className="flex-grow max-w-[450px] overflow-y-auto rounded p-4 bg-gray-100">
        {applicationsToCertify.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-center">
              <div className="mt-4 text-gray-500 max-w-[200px]">Nothing to do right now - you're all caught up!</div>
            </div>
          </div>
        ) : (
          applicationsToCertify.map(application => (
            <CertificationAction key={application.id} application={application} onActionSuccess={onActionSuccess} />
          ))
        )}
      </div>
    </div>
  );
};

export default ActionItems;