import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUpcomingPaymentsForSchool } from '../../api/applications';
import { UpcomingPaymentData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV, formatPhone } from '../../utils/formatters';
import StatusPill from '../shared/StatusPill';

const UpcomingPayments = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [upcomingPaymentsData, setUpcomingPaymentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUpcomingPaymentsForSchool(activeSchool.id);
        const formattedData = data.map(item => new UpcomingPaymentData(item));
        const sortedData = formattedData.sort((a, b) => a.nextPaymentDate - b.nextPaymentDate);
        setUpcomingPaymentsData(sortedData);
      } catch (error) {
        console.error("Error fetching upcoming payments data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!activeSchool.is_admin) {
      navigate('/home');
    } else {
      fetchData();
    }
  }, [activeSchool.id]);

  const exportToCSV = () => {
    const headers = [
      "Student",
      "Program",
      "Next Payment Date",
      "Monthly Payment",
      "Email",
      "Phone",
      "Graduation Date",
      "Currently In School",
      "Is First Payment",
      "Autopay Enabled",
      "Registered For Payment Portal On",
      "Last Login To Payment Portal",
      "Last Call Attempt by ZuntaFi",
      "Last Conversation with ZuntaFi",
      "Days Delinquent",
      "Past Due Amount",
      "Loan Counseling Scheduled For",
      "Loan Counseling Completed On",
    ];
  
    const csvData = upcomingPaymentsData.map(item => [
      item.studentName,
      item.programName,
      formatDateForCSV(item.nextPaymentDate),
      formatCurrencyForCSV(item.monthlyPayment),
      item.email,
      item.phone,
      formatDateForCSV(item.gradDate),
      item.currentlyInSchool ? "Y" : "N",
      item.isFirstPayment ? "Y" : "N",
      item.autopayStatus === "Y" ? "Y" : "N",
      formatDateForCSV(item.registrationDate),
      formatDateForCSV(item.lastLogin),
      formatDateForCSV(item.lastCallAttemptDate),
      formatDateForCSV(item.lastCallDate),
      item.daysDelinquent,
      formatCurrencyForCSV(item.pastDueAmount),
      formatDateForCSV(item.loanCounselingScheduledFor),
      formatDateForCSV(item.loanCounselingCompletedOn),
    ]);
  
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      const today = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
      link.setAttribute('download', `Fortify Upcoming Payments ${today}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (upcomingPaymentsData.length === 0) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Upcoming Payments (next 30 days)</div>
        <hr />
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              There are no upcoming payments yet.<br/>Send your Fortify application link to more students to start collecting payments.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Upcoming Payments (next 30 days)</div>
        <button
          onClick={exportToCSV}
          className="bg-fortify-green hover:bg-fortify-green-dark text-white font-semibold py-2 px-4 rounded flex items-center"
        >
          Export to CSV
          <img src="/white_download_icon.svg" alt="Download CSV" className="w-5 h-5 ml-2" />
        </button>
      </div>
      <hr />
      <div className="flex-grow overflow-hidden">
        <div className="overflow-x-auto overflow-y-auto h-full">
          <table className="w-full table-auto border border-gray-300">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Student</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Program</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Next Payment Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Monthly Payment</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Email</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Phone</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Graduation Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Currently In School</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Is First Payment</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Autopay Enabled</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Registered For Payment Portal On</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Login To Payment Portal</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Call Attempt by ZuntaFi</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Last Conversation with ZuntaFi</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Days Delinquent</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Past Due Amount</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Loan Counseling Scheduled For</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Loan Counseling Completed On</th>
              </tr>
            </thead>
            <tbody>
              {upcomingPaymentsData.map((item, index) => {
                const shouldHighlight = item.daysDelinquent > 0 || (item.autopayStatus !== "Y" && !item.registrationDate);
                return (
                  <tr key={index} className={`border-b h-12 ${shouldHighlight ? 'bg-[#ffc2c2]' : ''}`}>
                    <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.nextPaymentDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.monthlyPayment)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <a href={`mailto:${item.email}`} className="text-black no-underline hover:underline">
                        {item.email}
                      </a>
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatPhone(item.phone)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.gradDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={item.currentlyInSchool ? "Yes" : "No"} backgroundColor={item.currentlyInSchool ? "bg-green-700" : "bg-gray-500"} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={item.isFirstPayment ? "Yes" : "No"} backgroundColor={item.isFirstPayment ? "bg-green-700" : "bg-gray-500"} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">
                      <StatusPill text={(item.autopayStatus === "Y") ? "Yes" : "No"} backgroundColor={(item.autopayStatus === "Y") ? "bg-green-700" : "bg-red-700"} />
                    </td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.registrationDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastLogin)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallAttemptDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.lastCallDate)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{item.daysDelinquent}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.pastDueAmount)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.loanCounselingScheduledFor)}</td>
                    <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.loanCounselingCompletedOn)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UpcomingPayments;