import * as Sentry from "@sentry/react";

const sortLoanApplications = (loanApplications) => {
    const sortedApplications = {
      pendingApplications: [],
      pendingSchoolCertificationApplications: [],
      pendingIDVerificationApplications: [],
      pendingDownPaymentApplications: [],
      pendingSignatureApplications: [],
      fundedApplications: [],
      ineligibleApplications: [],
      archivedApplications: [],
      rejectedApplications: [],
      cancelledApplications: [],
    };
  
    loanApplications.forEach(application => {
      switch (application.nextStep) {
        case 'archived':
          sortedApplications.archivedApplications.push(application);
          break;
        case 'ineligible':
          sortedApplications.ineligibleApplications.push(application);
          break;
        case 'rejected':
        case 'school_rejected':
          sortedApplications.rejectedApplications.push(application);
          break;
        case 'email_verification':
        case 'personal_information':
        case 'decisioning':
        case 'approval':
          sortedApplications.pendingApplications.push(application);
          break;
        case 'verify_tuition':
          const requiredDownPaymentAsFloat = (!application.loan.requiredDownPayment ? 0 : parseFloat(application.loan.requiredDownPayment))

          if (!!application.loan.downPaymentReceivedOn) {
            sortedApplications.pendingSignatureApplications.push(application);
          } else if (requiredDownPaymentAsFloat > 0) {
            sortedApplications.pendingDownPaymentApplications.push(application);
          } else {
            sortedApplications.pendingSignatureApplications.push(application);
          }
          break;
        case 'down_payment':
          sortedApplications.pendingDownPaymentApplications.push(application);
          break;
        case 'signature':
          sortedApplications.pendingSignatureApplications.push(application);
          break;
        case 'autopay_prompt':
        case 'completed':
          sortedApplications.fundedApplications.push(application);
          break;
        case 'cancelled':
          sortedApplications.cancelledApplications.push(application);
          break;
        case 'identity_verification':
        case 'pending_school_certification':
        case 'failed_identity_verification':
        case 'pending_bank_verification':
          if (
            application.schoolCertification === "pending" ||
            application.tuitionRequestStatus === "pending_school_approval"
          ) {
            sortedApplications.pendingSchoolCertificationApplications.push(application);
          } else {
            sortedApplications.pendingIDVerificationApplications.push(application);
          }
          break;
        default:
          Sentry.captureException(new Error(`Unexpected nextStep ${application.nextStep} for application ${application.id}`));
      }
    });
  
    return sortedApplications;
  };
  
  export default sortLoanApplications;