import React, { useState, useEffect } from 'react';
import { fetchAllRepsForSchool, inviteNewRepToSchool, deleteRepAtSchool } from '../../../api/reps';
import { CircularProgress, Alert } from '@mui/material';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const MemberSettings = ({ activeSchool, user }) => {
  const [isGetMembersApiCallLoading, setIsGetMembersApiCallLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isInviteApiCallLoading, setIsInviteApiCallLoading] = useState(false);
  const [inviteApiError, setInviteApiError] = useState(null);

  const [inviteFirstName, setInviteFirstName] = useState('');
  const [inviteLastName, setInviteLastName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState('');
  const [fieldError, setFieldError] = useState({ firstName: '', lastName: '', email: '', role: '' });

  const [memberToRemove, setMemberToRemove] = useState(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRemoveApiCallLoading, setIsRemoveApiCallLoading] = useState(false);
  const [removeApiError, setRemoveApiError] = useState(null);

  const loadReps = async () => {
    try {
      setIsGetMembersApiCallLoading(true);
      const data = await fetchAllRepsForSchool(activeSchool.id);
      let reps = data.reps;
      if (process.env.REACT_APP_APP_ENV === 'prd') {
        reps = reps.filter(rep => !rep.email.includes("@fortifyeducation.com"))
      }
      setTeamMembers(reps);
    } catch (error) {
      logErrorToConsole("Error fetching reps data", error);
      logErrorToSentryWithContext(error, {
        schoolId: activeSchool.id,
      });
    } finally {
      setIsGetMembersApiCallLoading(false);
    }
  };

  useEffect(() => {
    if (activeSchool && activeSchool.id) {
      loadReps();
    }
  }, [activeSchool]);

  const handleInviteClick = () => {
    setIsInviteModalOpen(true);
  };

  const handleCloseInviteModal = () => {
    setIsInviteModalOpen(false);
    setInviteFirstName('');
    setInviteLastName('');
    setInviteEmail('');
    setInviteRole('');
    setFieldError({ firstName: '', lastName: '', email: '', role: '' });
  };

  const handleInviteFirstNameChange = (e) => {
    setInviteFirstName(e.target.value);
    setFieldError({ ...fieldError, firstName: '' });
  };

  const handleInviteLastNameChange = (e) => {
    setInviteLastName(e.target.value);
    setFieldError({ ...fieldError, lastName: '' });
  };

  const handleInviteEmailChange = (e) => {
    setInviteEmail(e.target.value);
    setFieldError({ ...fieldError, email: '' });
  };

  const handleInviteRoleChange = (e) => {
    setInviteRole(e.target.value);
    setFieldError({ ...fieldError, role: '' });
  };

  const handleExecuteInvite = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inviteFirstName.length < 3) {
      setFieldError({ ...fieldError, firstName: 'First name must be at least 3 characters long.' });
    } else if (inviteLastName.length < 3) {
      setFieldError({ ...fieldError, lastName: 'Last name must be at least 3 characters long.' });
    } else if (!emailRegex.test(inviteEmail)) {
      setFieldError({ ...fieldError, email: 'Please enter a valid email address.' });
    } else if (!inviteRole) {
      setFieldError({ ...fieldError, role: 'Please select a role.' });
    } else {
      try {
        setIsInviteApiCallLoading(true);
        setInviteApiError('');
        await inviteNewRepToSchool(activeSchool.id, {
          first_name: inviteFirstName,
          last_name: inviteLastName,
          email: inviteEmail,
          is_admin: inviteRole === 'Admin'
        });
        setIsInviteModalOpen(false);
        setInviteFirstName('');
        setInviteLastName('');
        setInviteEmail('');
        setInviteRole('');
        loadReps();
      } catch (error) {
        logErrorToConsole("Failed to invite new rep to school", error);
        logErrorToSentryWithContext(error, {
          schoolId: activeSchool.id,
          inviteFirstName,
          inviteLastName,
          inviteEmail,
          inviteRole,
        });

        setInviteApiError(error?.response?.data?.error || "There was an error inviting this rep.");
      } finally {
        setIsInviteApiCallLoading(false);
      }
    }
  };

  const handleRemoveClick = (member) => {
    setMemberToRemove(member);
    setIsRemoveModalOpen(true);
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalOpen(false);
    setMemberToRemove(null);
  };

  const handleExecuteRemove = async () => {
    setIsRemoveApiCallLoading(true);
    try {
      setRemoveApiError('');
      await deleteRepAtSchool(activeSchool.id, memberToRemove.rep_id);
      setIsRemoveModalOpen(false);
      setMemberToRemove(null);
      loadReps();
    } catch (error) {
      logErrorToConsole("Error removing rep", error);
      logErrorToSentryWithContext(error, {
        schoolId: activeSchool.id,
        repId: memberToRemove.rep_id,
      });

      setRemoveApiError(error?.response?.data?.error || "There was an error removing this rep.")
    } finally {
      setIsRemoveApiCallLoading(false);
    }
  };

  const handleOutsideClick = (e, closeModal) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div className="w-full max-w-4xl flex flex-col h-full overflow-hidden">
      <div className="flex justify-between items-center mb-8">
        <h1 className="page-title">Team Members</h1>
        {activeSchool.is_admin ? (
          <button
            className="bg-fortify-green text-white px-4 py-2 rounded flex items-center"
            onClick={handleInviteClick}
          >
            <img src="/add_user.svg" alt="Add User" className="w-5 h-5 mr-2" />
            Invite
          </button>
        ) : <></>}
      </div>

      <div className="bg-gray-100 rounded-t-lg border border-gray-300 p-4">
        <span className="text-gray-700 font-bold font-lora">Members</span>
      </div>

      {isGetMembersApiCallLoading ? (
        <div className="min-w-full bg-white border border-gray-300 h-60 flex items-center justify-center">
          <CircularProgress size={20}/>
        </div>
      ) : teamMembers.length === 0 ? (
        <div className="min-w-full bg-white border border-gray-300 h-60 flex items-center justify-center text-gray-700">
          No team members yet
        </div>
      ) : (
        <div className="flex-grow overflow-y-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <tbody>
              {teamMembers.map((member, index) => (
                <tr key={index} className="border-b">
                  <td className="flex items-center py-3 px-6">
                    <img src="/person.svg" alt="Profile" className="w-8 h-8 rounded-full mr-4" />
                    <div>
                      <div className="text-xl text-black">
                        {`${member.first_name} ${member.last_name}`}
                        {member.email === user.email && ' (You)'}
                      </div>
                      <div className="text-xs text-gray-500">{member.email}</div>
                    </div>
                  </td>
                  <td className="pr-12 text-gray-400 text-base">
                    {member.is_admin ? 'Admin' : 'Rep'}
                  </td>
                  <td className="pr-12 text-gray-400 text-base">
                    {member.created_password_at ? '' : <p className="text-xs text-gray-400 inline-block border border-gray-300 rounded-sm p-2">INVITED</p>}
                  </td>
                  {activeSchool.is_admin && member.email !== user.email && (
                    <td className="text-right">
                      <button
                        className="bg-white border border-gray-300 rounded px-2 py-1 flex items-center hover:bg-gray-100"
                        onClick={() => handleRemoveClick(member)}
                      >
                        <img src="/remove.svg" alt="Remove User" className="w-3 h-3 mr-2" />
                        {member.created_password_at ? 'Remove' : 'Cancel'}
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isInviteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={(e) => handleOutsideClick(e, handleCloseInviteModal)}>
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 font-lora">Invite a team member</h2>

            {fieldError.firstName && <p className="text-red-500 text-sm mb-2">{fieldError.firstName}</p>}
            <div className="flex space-x-4 mb-4">
              <input
                type="text"
                placeholder="First Name"
                value={inviteFirstName}
                onChange={handleInviteFirstNameChange}
                className="w-1/2 p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                placeholder="Last Name"
                value={inviteLastName}
                onChange={handleInviteLastNameChange}
                className="w-1/2 p-2 border border-gray-300 rounded"
              />
            </div>

            {fieldError.email && <p className="text-red-500 text-sm mb-2">{fieldError.email}</p>}
            <input
              type="email"
              placeholder="Email"
              value={inviteEmail}
              onChange={handleInviteEmailChange}
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />

            <div className="mb-4">
              {fieldError.role && <p className="text-red-500 text-sm mb-2">{fieldError.role}</p>}
              <div className="flex mb-2 space-x-8">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="Rep"
                    checked={inviteRole === 'Rep'}
                    onChange={handleInviteRoleChange}
                    className="mr-2"
                  />
                  Rep
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="Admin"
                    checked={inviteRole === 'Admin'}
                    onChange={handleInviteRoleChange}
                    className="mr-2"
                  />
                  Admin
                </label>
              </div>

              <p className="text-sm text-gray-500">
                Only admins have access to Performance and Disbursements, and only admins can invite additional reps.
              </p>
            </div>

            {inviteApiError && <Alert severity="error" style={{ marginBottom: '1rem' }}>{inviteApiError}</Alert>}

            <div className="flex space-x-4 justify-center">
              {isInviteApiCallLoading ? (
                <CircularProgress className="mt-2" size={25} />
              ) : (
                <>
                  <button
                    className="w-1/2 px-4 py-2 rounded border border-fortify-green text-fortify-green"
                    onClick={handleCloseInviteModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-1/2 px-4 py-2 rounded bg-fortify-green text-white"
                    onClick={handleExecuteInvite}
                  >
                    Invite
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {isRemoveModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={(e) => handleOutsideClick(e, handleCloseRemoveModal)}>
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 font-lora">Remove Member</h2>
            <p className="mb-4">Are you sure you want to remove {memberToRemove?.first_name} {memberToRemove?.last_name}?</p>

            {removeApiError && <Alert severity="error" style={{ marginBottom: '1rem' }}>{removeApiError}</Alert>}

            <div className="flex space-x-4 justify-center">
              {isRemoveApiCallLoading ? (
                <CircularProgress className="mt-2" size={25} />
              ) : (
                <>
                  <button
                    className="w-1/2 px-4 py-2 rounded border border-fortify-green text-fortify-green"
                    onClick={handleCloseRemoveModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="w-1/2 px-4 py-2 rounded bg-fortify-green text-white"
                    onClick={handleExecuteRemove}
                  >
                    Remove
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberSettings;
