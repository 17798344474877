import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = ({ selectedTab, handleSelectTab, handleLogout, user, activeSchool, authorizedSchools, setActiveSchool }) => {
  const [isSchoolDropdownOpen, setIsSchoolDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const defaultLogo = "https://fortifyedu.retool.com/api/file/829aa8ff-fe75-4ec2-b137-c8b7175e764c"

  const getIcon = (tab) => {
    const isActive = selectedTab === tab.replace(/\s+/g,'-').toLowerCase(); 
    return `./sidebarIcons/${tab.replace(/\s+/g,'-').toLowerCase()}_${isActive ? 'active' : 'inactive'}.svg`;
  };

  const handleSchoolClick = () => {
    if (authorizedSchools.length > 1) {
      setIsSchoolDropdownOpen(!isSchoolDropdownOpen);
    }
  };

  const handleSchoolSelect = (school) => {
    setActiveSchool(school);
    setIsSchoolDropdownOpen(false);
    navigate('/home');
  };

  const sidebarStructure = [
    { tab: 'Home', section: null },
    {
      section: 'New Applications',
      tabs: ['All Applications', 'Live States', 'Payment Estimator']
    },
    {
      section: 'Repayments',
      tabs: ['Upcoming Payments', 'Loan Portfolio', 'Past Due']
    },
    {
      section: 'Accounting',
      tabs: ['Down Payments', 'Payment History', 'Disbursements']
    }
  ]

  const renderTab = (tab) => (
    <div
      key={tab}
      className={`mt-1 sidebar-link ${selectedTab === tab.replace(/\s+/g, '-').toLowerCase() ? 'sidebar-link-active' : 'sidebar-link-inactive'}`}
      onClick={() => handleSelectTab(tab.replace(/\s+/g, '-').toLowerCase())}
    >
      <Link to={`/${tab.toLowerCase()}`} className="no-underline flex items-center">
        <img src={getIcon(tab)} alt={`${tab} icon`} className="page-icon mr-2" />
        {tab}
      </Link>
    </div>
  )

  const renderSection = (section) => (
    <div key={section.section}>
      <div className="text-sm lg:text-base font-semibold text-gray-600 mt-8 ml-1 mb-2">{section.section}</div>
      {section.tabs.map(renderTab)}
    </div>
  )

  const renderSidebar = () => {
    if (activeSchool.is_admin) {
      return sidebarStructure.map(item => {
        if (item.section === 'Accounting') {
          const accountingTabs = activeSchool.is_collecting_down_payment_with_fortify
            ? item.tabs
            : item.tabs.filter(tab => tab !== 'Down Payments');
          return renderSection({ ...item, tabs: accountingTabs });
        }
        return item.section ? renderSection(item) : renderTab(item.tab);
      });
    } else {
      return [
        renderTab('Home'),
        renderSection({
          section: 'New Applications',
          tabs: ['All Applications', 'Live States']
        })
      ]
    }
  }

  return (
    <div className="w-[350px] 2xl:w-[400px] border-r border-gray-200">
      <div className="flex flex-col justify-between h-full mt-4 sidebar-container">
        <div>
          <div
            className={`px-0 py-3 rounded-md flex items-center ${authorizedSchools.length > 1 ? 'hover:bg-gray-200 cursor-pointer' : ''}`}
            onClick={handleSchoolClick}
          >
            <img src={(activeSchool.school_logo || defaultLogo)} alt="School Logo" className="w-[45px] h-[45px] mr-3 rounded-sm" />
            <div className="text-base max-w-[200px] flex items-center">
              {activeSchool.school_name}
            </div>
            {authorizedSchools.length > 1 && (
              <img
                src={isSchoolDropdownOpen ? "/chevron_up.svg" : "/chevron_down.svg"}
                alt="Dropdown"
                className="w-6 h-6 ml-6"
              />
            )}
          </div>
          {isSchoolDropdownOpen && (
            <div className="bg-white border rounded shadow-md mt-1 max-h-48 overflow-y-auto">
              {authorizedSchools.filter(school => school.id !== activeSchool.id).map(school => (
                <div
                  key={school.id}
                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSchoolSelect(school)}
                >
                  <img src={(school.school_logo || defaultLogo)} alt="School Logo" className="w-8 h-8 xl:w-10 xl:h-10 mr-3 rounded-sm" />
                  <div className="text-sm lg:text-base">{school.school_name}</div>
                </div>
              ))}
            </div>
          )}
          <div className="mt-10 space-y-2">
            {renderSidebar()}
          </div>
        </div>

        <div>
          <div className="mt-4 space-y-2">
            <div
              className={`sidebar-link ${selectedTab === "contact" ? 'sidebar-link-active' : 'sidebar-link-inactive'}`}
              onClick={() => handleSelectTab("contact")}
            >
              <Link to="/contact" className="no-underline flex items-center">
                <img src={getIcon('Contact')} alt="Contact icon" className="page-icon mr-2" />
                Contact us
              </Link>
            </div>

            <div
              className={`sidebar-link ${selectedTab === "settings" ? 'sidebar-link-active' : 'sidebar-link-inactive'}`}
              onClick={() => handleSelectTab("settings")}
            >
              <Link to="/settings" className="no-underline flex items-center">
                <img src={getIcon('Settings')} alt="Settings icon" className="page-icon mr-2" />
                Settings
              </Link>
            </div>

            <div className="sidebar-link sidebar-link-inactive flex items-center" onClick={handleLogout}>
              <img src="./sidebarIcons/logout.svg" alt="Logout icon" className="page-icon mr-2" />
              Logout
            </div>
          </div>

          <div className="mt-10 mb-12 flex items-center">
            <img src="/person.svg" alt="Profile" className="w-6 h-6 lg:w-8 lg:h-8 mr-3 rounded-full" />
            <div>
              <div className="text-sm lg:text-base">{user.name}</div>
              <div className="text-gray-400 text-xs lg:text-sm">{user.email}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;