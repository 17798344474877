import React, { useState } from 'react';
import AccountSettings from './AccountSettings';
import MemberSettings from './MemberSettings';
import SchoolSettings from './SchoolSettings';
import ProgramSettings from './ProgramSettings';

const Settings = ({ user, activeSchool }) => {
  const [selectedSettingsTab, setSelectedSettingsTab] = useState('team members');

  const handleSelectSettingsTab = (tab) => {
    setSelectedSettingsTab(tab);
  };

  return (
    <div className="flex h-full overflow-hidden">

      <div className="h-full">
        <div className="h-full flex flex-col xl:ml-3 2xl:ml-6 border-r border-gray-300">
          {['Team Members', 'Live Programs', 'School Info'].map((tab) => (
            <div
              key={tab}
              className={`min-w-[125px] xl:min-w-[150px] 2xl:min-w-[175px] cursor-pointer py-2 ${selectedSettingsTab === tab.toLowerCase() ? 'text-black font-bold' : 'text-gray-500'}`}
              onClick={() => handleSelectSettingsTab(tab.toLowerCase())}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>

      <div className="flex-grow pl-8 overflow-hidden">
        {selectedSettingsTab === 'account' && <AccountSettings user={user} />}
        {selectedSettingsTab === 'team members' && <MemberSettings user={user} activeSchool={activeSchool} />}
        {selectedSettingsTab === 'school info' && <SchoolSettings activeSchool={activeSchool} />}
        {selectedSettingsTab === 'live programs' && <ProgramSettings activeSchool={activeSchool} />}
      </div>

    </div>
  );
};

export default Settings;