import React from 'react';
import FortifyIcon from './FortifyIcon';
import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToConsole("Error caught by ErrorBoundary", error);
    logErrorToSentryWithContext(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-fortify-green">
          <div className="flex flex-col items-center p-8 bg-white rounded-sm shadow-lg w-11/12 max-w-md text-center">
            <FortifyIcon />
            <h1 className="text-2xl font-bold mt-2 font-lora">Oops! Something went wrong.</h1>
            <p className="mt-4">
              We're sorry for the inconvenience. Our team has been notified, and we're working on the issue.
            </p>
            <button 
              className="mt-6 px-4 py-2 bg-fortify-green text-white rounded hover:bg-fortify-green transition-colors"
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;