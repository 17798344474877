import React from 'react';

const SchoolSettings = ({ activeSchool }) => {
  const { school_name, school_street, school_street_line_two, school_city, school_state, school_zip } = activeSchool;

  const defaultLogo = "https://fortifyedu.retool.com/api/file/829aa8ff-fe75-4ec2-b137-c8b7175e764c"

  return (
    <div className="w-full max-w-4xl">
      <h1 className="page-title mb-8">School Info</h1>

      <div className="flex items-center mb-8">
        <img src={(activeSchool.school_logo || defaultLogo)} alt="School Logo" className="w-10 h-10 rounded-full mr-6" />
        <div>
          <div className="text-xl font-bold">{school_name}</div>
          <div className="text-gray-500 text-sm">{school_city}, {school_state}</div>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex space-x-6">
          <div className="flex-grow">
            <label className="block text-gray-700">School Name</label>
            <input type="text" value={school_name} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
        </div>

        <div className="flex space-x-6">
          <div className="flex-grow">
            <label className="block text-gray-700">Address Line 1</label>
            <input type="text" value={school_street} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
          <div className="flex-grow">
            <label className="block text-gray-700">Address Line 2</label>
            <input type="text" value={school_street_line_two} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
        </div>

        <div className="flex space-x-6">
          <div className="flex-grow">
            <label className="block text-gray-700">City</label>
            <input type="text" value={school_city} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
          <div className="flex-grow">
            <label className="block text-gray-700">State</label>
            <input type="text" value={school_state} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
          <div className="flex-grow">
            <label className="block text-gray-700">Zip Code</label>
            <input type="text" value={school_zip} readOnly className="w-full p-2 border border-gray-300 rounded bg-gray-100" />
          </div>
        </div>

      </div>

    </div>
  );
};

export default SchoolSettings;
