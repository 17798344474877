import React, { useState } from 'react';
import { CSVLink } from "react-csv";

const ApplicationStageTable = ({ stageTitle, loanApplicationsData, stageIcon, isApplicationFrameOpen, onSelectApplication }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const chevronRight = "/chevron_right.svg"
  const chevronDown = "/chevron_down.svg"

  const prepareCSVData = () => {
    const headers = ["Student Name", "Program", "Application Created Date", "Program Start Date"];

    // Ah, Javascript. createdAt is a timestamp, so it's read correctly. Print it in America/Chicago, not UTC.
    const data = loanApplicationsData.map(app => [
      `${app.student.account.firstName} ${app.student.account.lastName}`,
      app.program.programName,
      app.createdAt ? new Date(app.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'America/Chicago' }) : "",
      app.startDate ? new Date(app.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }) : ""
    ]);
    return [headers, ...data];
  };

  const handleToggleExpand = () => {
    if (!isApplicationFrameOpen) {
      setIsExpanded(!isExpanded);
    }
  };

  const renderApplicationCount = () => {
    const count = loanApplicationsData.length;
    return `${count} ${count === 1 ? 'student' : 'students'}`;
  };

  const handleRowClick = (applicationData, event) => {
    if (!isApplicationFrameOpen) {
      onSelectApplication(applicationData);
      event.stopPropagation();
    }
  };

  return (
    <div className="w-full py-4 flex flex-col">

      <div className="flex items-center">
        <img src={stageIcon} alt="Stage Icon" className="w-10 h-10 mr-4" />
        <div className="flex-grow">
          <div
            className={`flex items-center bg-gray-200 rounded-lg px-4 py-3 ${!isApplicationFrameOpen ? 'cursor-pointer' : ''}`}
            onClick={handleToggleExpand}
          >
            <div className="flex-grow justify-between flex items-center">
              <div className="flex flex-row space-x-2 items-center">
                <img src={isExpanded ? chevronDown : chevronRight} alt="Chevron" className="w-6 h-6" />
                <div className="text-xl font-[600] font-lora">{stageTitle}</div>
              </div>
              <div className="flex flex-row space-x-4 items-center">
                <div className="text-lg text-gray-600">{renderApplicationCount()}</div>
                <CSVLink
                  data={prepareCSVData()}
                  filename={`Fortify ${stageTitle}.csv`}
                  className={loanApplicationsData.length === 0 ? 'pointer-events-none' : ''}
                >
                  <img
                    src="/download_icon.svg"
                    alt="Download CSV"
                    className={`w-5 h-5 ${loanApplicationsData.length === 0 ? 'opacity-20' : 'cursor-pointer'}`}
                    onClick={loanApplicationsData.length > 0 ? (e) => e.stopPropagation() : undefined}
                  />
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="flex">
          <div className="w-10 h-10 mr-4"></div>

          <div className="flex-grow pt-2 px-4 border-l border-b border-r border-gray-300 rounded-bl-lg rounded-br-lg">
            {loanApplicationsData.length === 0 ? (
              <div className="min-h-[100px] flex items-center justify-center text-gray-500">
                No applications found
              </div>
            ) : (
              <div className="relative">
                <div className="sticky top-0 bg-white">
                  <table className="w-full border-collapse">
                    <colgroup>
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '8%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="border-b p-2 text-lg text-left">Student</th>
                        <th className="border-b p-2 text-lg text-left">Program</th>
                        <th className="border-b p-2 text-lg text-left">App Started On</th>
                        <th className="border-b p-2 text-lg text-left">Start Date</th>
                        <th className="border-b p-2 text-lg text-left"></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="max-h-72 overflow-y-auto">
                  <table className="w-full border-collapse">
                    <colgroup>
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '23%' }} />
                      <col style={{ width: '8%' }} />
                    </colgroup>
                    <tbody>
                      {loanApplicationsData.map((applicationData, index) => (
                        <tr key={index} onClick={(event) => handleRowClick(applicationData, event)} className={!isApplicationFrameOpen ? "cursor-pointer" : ""}>
                          <td className="border-b p-2">{applicationData.student.account.firstName} {applicationData.student.account.lastName}</td>
                          <td className="border-b p-2">{applicationData.program.programName}</td>

                          {/* Ah, Javascript. createdAt is a timestamp, so it's read correctly. Print it in America/Chicago, not UTC. */}
                          <td className="border-b p-2">{!applicationData.createdAt ? "" : (
                            new Date(applicationData.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'America/Chicago' })
                          )}</td>
                          <td className="border-b p-2">{!applicationData.startDate ? "" : (
                            new Date(applicationData.startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
                          )}</td>
                          <td className="border-b p-2 text-right justify-right">
                            <img src="/expand_row.svg" alt="Details" className="w-6 h-6 cursor-pointer" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>

        </div>
      )}
    </div>
  );
};

export default ApplicationStageTable;
