import React, { useState } from 'react';
import { Button } from '@mui/material';
import { completionStatusBox, certificationStatusBox, verificationStatusBox, downPaymentStatusBox, signatureStatusBox } from './stages';
import ArchiveModal from '../actions/ArchiveModal';
import UnarchiveModal from '../actions/UnarchiveModal';
import CertificationModal from '../actions/CertificationModal';
import ResetCertificationModal from '../actions/ResetCertificationModal';

const ApplicationFrame = ({ isOpen, applicationData, onClose, refreshPortalDataOnAction }) => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] = useState(false);
  const [isResetCertificationModalOpen, setIsResetCertificationModalOpen] = useState(false);

  if (!isOpen) return null;

  const {
    student: { account: { firstName, lastName, email, phone }, markedIneligibleAt },
    stageSummary,
    createdAt,
    startDate,
    gradDate,
    schoolCertification,
    program: { programName },
    loan: { requiredDownPayment, executedOn },
    archivedAt,
    tuitionRequestStatus
  } = applicationData;


  const requiredDownPaymentFloat = (!requiredDownPayment ? 0 : parseFloat(requiredDownPayment))

  const shouldShowArchiveAction = !archivedAt && !executedOn && !markedIneligibleAt;
  const shouldShowUnarchiveAction = !!archivedAt;
  const shouldShowCertifyAction = !archivedAt && tuitionRequestStatus === 'pending_school_approval';
  const shouldShowResetCertificationAction = !archivedAt && !executedOn && schoolCertification === 'approved';

  const handleArchive = () => {
    setIsArchiveModalOpen(true);
  };

  const handleUnarchive = () => {
    setIsUnarchiveModalOpen(true);
  }

  const handleCertify = () => {
    setIsCertificationModalOpen(true);
  };

  const handleResetCertification = () => {
    setIsResetCertificationModalOpen(true);
  }

  const formatCurrency = (amount) => {
    if (amount == null) return "";
    return `$${parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  return (
    <div className="right-frame-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-end pointer-events-none">
      <div className="right-frame bg-white w-1/2 xl:w-2/5 2xl:w-1/3 h-screen flex flex-col pointer-events-auto">
        <div className="px-8 pt-8">
          {/* Header content */}
          <div className="flex justify-between items-start mb-2">
            <div className="text-3xl font-bold font-lora">{`${firstName} ${lastName}`}</div>
            <button onClick={onClose} className="close-button">
              <img src="/close.svg" alt="Close" />
            </button>
          </div>
          <div className="text-lg mt-3 mb-3">{`${firstName}${stageSummary}`}</div>
          <div className="flex flex-row items-start w-full space-x-12 text-gray-500">
            <div>{email}</div>
            <div>{`(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`}</div>
          </div>

          <hr className="my-4" />

        </div>

        {/* Scrollable content */}
        <div className="flex-1 overflow-y-auto px-8 pb-8">
          {/* Stages */}
          {(shouldShowArchiveAction || shouldShowUnarchiveAction || shouldShowCertifyAction || shouldShowResetCertificationAction) && (
            <>
              <h2 className="application-frame-section-title">Actions</h2>
              <div className="flex flex-col w-full space-y-3">
                {shouldShowCertifyAction && (
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: '#12543b', color: 'white' }}
                    onClick={handleCertify}
                  >
                    Certify
                  </Button>
                )}
                {shouldShowResetCertificationAction && (
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: '#DC2626', color: 'white' }}
                    onClick={handleResetCertification}
                  >
                    Reset to Certification
                  </Button>
                )}
                {shouldShowArchiveAction && (
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: '#5a5a5a', color: 'white' }}
                    onClick={handleArchive}
                  >
                    Archive
                  </Button>
                )}
                {shouldShowUnarchiveAction && (
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: '#12543b', color: 'white' }}
                    onClick={handleUnarchive}
                  >
                    Unarchive
                  </Button>
                )}
              </div>
              <hr className="my-4" />
            </>
          )}

          <h2 className="application-frame-section-title">Application Stage</h2>

          {/* Ah, Javascript. createdAt is a timestamp, so it's read correctly. Print it in America/Chicago, not UTC. */}
          <div className="mb-4">Application started on: {new Date(createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'America/Chicago' })}</div>
          <div className="flex flex-col w-full space-y-3">
            {completionStatusBox(applicationData)}
            {certificationStatusBox(applicationData)}
            {verificationStatusBox(applicationData)}
            {requiredDownPaymentFloat > 0 && downPaymentStatusBox(applicationData)}
            {signatureStatusBox(applicationData)}
          </div>

          <hr className="my-4" />

          {/* Program Info */}
          <div>
            <h2 className="application-frame-section-title">Program Info</h2>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-2 mb-2"><span className="font-[600]">Program:</span> {programName}</div>
              <div><span className="font-[600]">Start date:</span> {new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })}</div>
              <div><span className="font-[600]">Graduation date:</span> {new Date(gradDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })}</div>
            </div>
          </div>

          <hr className="my-4" />

          {/* Loan Terms */}
          {applicationData.schoolCertification === "approved" && (
            <>
              <div>
                <h2 className="application-frame-section-title">Loan Terms</h2>
                <div className="grid grid-cols-2 gap-2">
                  <div className="mb-2"><span className="font-[600]">Tuition Financed:</span> {formatCurrency(applicationData.loan.tuitionFinanced)}</div>
                  <div className="mb-2"><span className="font-[600]">Borrowing Amount:</span> {formatCurrency(applicationData.loan.borrowingAmount)}</div>
                  <div className="mb-2"><span className="font-[600]">Interest Rate:</span> {(applicationData.loan.interestRate * 100).toFixed(2)}%</div>
                  <div className="mb-2"><span className="font-[600]">Loan Term:</span> {applicationData.loan.loanTerm} months</div>
                  <div className="mb-2"><span className="font-[600]">Monthly Payment:</span> {formatCurrency(applicationData.loan.monthlyPayment)}</div>
                  <div className="mb-2"><span className="font-[600]">First Payment Date:</span> {new Date(applicationData.loan.firstPaymentDueDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })}</div>
                </div>
              </div>
              <hr className="my-4" />
            </>
          )}

          <div>
            <h2 className="application-frame-section-title">Payments</h2>
            <div className="bg-gray-100 min-h-[150px] flex items-center justify-center rounded-sm">No data yet - please check back soon.</div>
          </div>
        </div>

        {shouldShowArchiveAction && <ArchiveModal
          open={isArchiveModalOpen}
          onClose={() => setIsArchiveModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}

        {shouldShowUnarchiveAction && <UnarchiveModal
          open={isUnarchiveModalOpen}
          onClose={() => setIsUnarchiveModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}

        {shouldShowResetCertificationAction && <ResetCertificationModal
          open={isResetCertificationModalOpen}
          onClose={() => setIsResetCertificationModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}

        {shouldShowCertifyAction && <CertificationModal
          open={isCertificationModalOpen}
          onClose={() => setIsCertificationModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}
      </div>
    </div>
  );
};

export default ApplicationFrame;
