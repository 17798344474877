class SchoolData {
    constructor(data) {
      this.id = data.id;
      this.is_admin = data.is_admin;
      this.school_name = data.school_name;
      this.school_street = data.school_street;
      this.school_street_line_two = data.school_street_line_two;
      this.school_city = data.school_city;
      this.school_state = data.school_state;
      this.school_zip = data.school_zip;
      this.school_logo = data.school_logo;
      this.is_collecting_down_payment_with_fortify = data.is_collecting_down_payment_with_fortify;
    }
}

export default SchoolData;