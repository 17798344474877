import React from 'react';
import FortifyIcon from './FortifyIcon';

const AccessOnDesktop = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-fortify-green">
      <div className="flex flex-col items-center p-8 bg-white rounded-sm shadow-lg w-11/12 max-w-xs text-center">
        <FortifyIcon />
        <h1 className="text-2xl font-bold mt-2 font-lora">Access on Desktop</h1>
        <p className="mt-4">
          The Fortify school portal is only designed for desktop. Please use a larger screen.
        </p>
        <br />
        <p>
          A mobile version of the portal is coming soon. We're sorry for the temporary inconvenience.
        </p>
      </div>
    </div>
  );
};

export default AccessOnDesktop;