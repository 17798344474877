import axios from 'axios';

let baseURL;

if (process.env.REACT_APP_APP_ENV === 'prd') {
  baseURL = 'https://api.fortifyedu.com/';
} else {
  baseURL = 'http://localhost:5000/';
}

axios.defaults.baseURL = baseURL;

const endpointsWithoutAccessTokenAuthentication = [
    '/auth/login',
    '/auth/refresh',
    '/auth/request-password-reset',
    '/auth/reset-password',
];

const ensureAccessToken = async () => {
    let token = localStorage.getItem('accessToken');
    if (!token) {
        // Wait for a short time and check again
        await new Promise(resolve => setTimeout(resolve, 100));
        token = localStorage.getItem('accessToken');
    }
    return token;
};

axios.interceptors.request.use(async config => {
    if (!endpointsWithoutAccessTokenAuthentication.some(endpoint => config.url.endsWith(endpoint))) {
        const token = await ensureAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;
    if (
        error.response.status === 401
        && error.response.data.msg === "Token has expired"
        && !originalRequest._retry
        && !endpointsWithoutAccessTokenAuthentication.some(endpoint => originalRequest.url.endsWith(endpoint))
    ) {
        originalRequest._retry = true;
        try {
            const accessToken = await refreshAccessToken();
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            return axios(originalRequest);
        } catch (refreshError) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login';

            // NOTE: we don't want this to appear in Sentry.
            //       However, we need to let it bubble up
            //       so we can filter out 401 errors.
            return Promise.reject(error);
        }
    }
    return Promise.reject(error);
});

async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post('/auth/refresh', {}, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          'Content-Type': 'application/json'
        }
      });
    localStorage.setItem('accessToken', response.data.access_token);
    return response.data.access_token;
}

export default axios;
