import React, { useState, useEffect } from 'react';
import ApplicationStageTable from './ApplicationStageTable';
import LoanApplicationData from '../../../models/LoanApplicationData';
import { fetchAllLoanApplicationsForSchool } from '../../../api/applications';
import { CircularProgress } from '@mui/material';

import sortLoanApplications from './sortLoanApplications';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const Applications = ({ activeSchool, isApplicationFrameOpen, onSelectApplication, refreshDataTrigger }) => {
  const [applicationsData, setApplicationsData] = useState(null);
  const [applicationsDataIsLoading, setApplicationsDataIsLoading] = useState(false);

  useEffect(() => {
    const fetchApplicationsData = async () => {
      if (activeSchool && activeSchool.id) {
        setApplicationsDataIsLoading(true);
        try {
          const data = await fetchAllLoanApplicationsForSchool(activeSchool.id);
          setApplicationsData(data);
        } catch (error) {
          logErrorToConsole("Error fetching applications data", error);
          logErrorToSentryWithContext(error, {
            schoolId: activeSchool.id,
          });
        } finally {
          setApplicationsDataIsLoading(false);
        }
      }
    };

    fetchApplicationsData();
  }, [activeSchool, refreshDataTrigger]);

  if (applicationsDataIsLoading || applicationsData?.matches === undefined) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  const loanApplications = applicationsData.matches.map(result => new LoanApplicationData(result));
  const sortedApplications = sortLoanApplications(loanApplications);

  const stage1Icon = "/applicationPageStages/stage1.svg"
  const stage2Icon = "/applicationPageStages/stage2.svg"
  const stage3Icon = "/applicationPageStages/stage3.svg"
  const stage4Icon = "/applicationPageStages/stage4.svg"
  const stage5Icon = "/applicationPageStages/stage5.svg"
  const stage6Icon = "/applicationPageStages/stage6.svg"
  const ineligibleIcon = "/applicationPageStages/stageIneligible.svg"
  const archivedIcon = "/applicationPageStages/stageArchived.svg"
  const rejectedIcon = "/applicationPageStages/stageRejected.svg"
  const cancelledIcon = "/applicationPageStages/stageCancelled.svg"

  const mainStages = [
    { title: 'Pending Submission', loanApplicationsData: sortedApplications.pendingApplications, stageIcon: stage1Icon },
    { title: 'Pending School Certification', loanApplicationsData: sortedApplications.pendingSchoolCertificationApplications, stageIcon: stage2Icon },
    { title: 'Pending ID Verification', loanApplicationsData: sortedApplications.pendingIDVerificationApplications, stageIcon: stage3Icon },
    { title: 'Pending Down Payment', loanApplicationsData: sortedApplications.pendingDownPaymentApplications, stageIcon: stage4Icon },
    { title: 'Pending Signature', loanApplicationsData: sortedApplications.pendingSignatureApplications, stageIcon: stage5Icon },
    { title: 'Funded Loans', loanApplicationsData: sortedApplications.fundedApplications, stageIcon: stage6Icon },
  ];

  const secondaryStages = [
    { title: 'Ineligible Apps', loanApplicationsData: sortedApplications.ineligibleApplications, stageIcon: ineligibleIcon },
    { title: 'Archived Apps', loanApplicationsData: sortedApplications.archivedApplications, stageIcon: archivedIcon },
    { title: 'Rejected Apps', loanApplicationsData: sortedApplications.rejectedApplications, stageIcon: rejectedIcon },
    { title: 'Cancelled Loans', loanApplicationsData: sortedApplications.cancelledApplications, stageIcon: cancelledIcon },
  ];

  return (
    <div className='flex flex-col h-full'>
      <div className="page-title">Applications</div>
      <hr className="mb-4"/>

      <div className="flex-1 overflow-auto">
        {mainStages.map((stage, index) => (
          <ApplicationStageTable
            key={index}
            stageTitle={stage.title}
            loanApplicationsData={stage.loanApplicationsData}
            stageIcon={stage.stageIcon}
            isApplicationFrameOpen={isApplicationFrameOpen}
            onSelectApplication={onSelectApplication}
          />
        ))}

        <hr className="my-4"/>

        {secondaryStages.map((stage, index) => (
          <ApplicationStageTable
            key={index}
            stageTitle={stage.title}
            loanApplicationsData={stage.loanApplicationsData}
            stageIcon={stage.stageIcon}
            isApplicationFrameOpen={isApplicationFrameOpen}
            onSelectApplication={onSelectApplication}
          />
        ))}
      </div>

    </div>
  );
};

export default Applications;