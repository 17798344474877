import { Button } from '@mui/material';
import React from 'react';

const HomeStats = ({ statsData, selectedPeriod, onPeriodChange }) => {
  const getCurrentMonthName = () => {
    return new Date().toLocaleString('en-US', { month: 'long', timeZone: 'America/Chicago' });
  };

  const getLastMonthName = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toLocaleString('en-US', { month: 'long', timeZone: 'America/Chicago' });
  };

  const buttonStyle = (period) => ({
    marginRight: '10px',
    fontWeight: 'bold',
    ...(selectedPeriod === period
      ? {
          backgroundColor: 'rgba(18, 84, 59, 0.92)',
          color: '#FFFFFF',
        }
      : {
          borderColor: '#12543b',
          color: '#12543b',
          backgroundColor: '#FFFFFF',
        }),
  });

  const {
    started_applications_in_period,
    started_tuition_financed_in_period,

    confirmed_applications_in_period,
    confirmed_tuition_financed_in_period,

    signed_applications_in_period,
    signed_tuition_financed_in_period,

    all_loans_count,
    all_loans_tuition_financed,
    all_loans_outstanding_balance,

    current_loans_count,
    current_loans_outstanding_balance,

    past_due_loans_count,
    past_due_loans_outstanding_balance,

    defaulted_loans_count,
    defaulted_loans_outstanding_balance
  } = statsData;

  return (
    <div className="h-full overflow-y-auto">
      <div className="home-page-section-title">Application Volume</div>

      <div className="flex mb-6">
        <Button
          variant={selectedPeriod === 'all_time' ? 'contained' : 'outlined'}
          onClick={() => onPeriodChange('all_time')}
          style={buttonStyle('all_time')}
        >
          All-time
        </Button>
        <Button
          variant={selectedPeriod === 'current_month' ? 'contained' : 'outlined'}
          onClick={() => onPeriodChange('current_month')}
          style={buttonStyle('current_month')}
        >
          {getCurrentMonthName()}
        </Button>
        <Button
          variant={selectedPeriod === 'last_month' ? 'contained' : 'outlined'}
          onClick={() => onPeriodChange('last_month')}
          style={buttonStyle('last_month')}
        >
          {getLastMonthName()}
        </Button>
      </div>
      
      <div className="flex stat-row">
        <div className="stat">
          <div className="home-stat-title">Started</div>
          <div className="home-stat-number">{started_applications_in_period}</div>
          <p className="stat-caption">${parseFloat(started_tuition_financed_in_period).toLocaleString()} in tuition</p>
        </div>
        <div className="stat">
          <div className="home-stat-title">Certified & Verified</div>
          <div className="home-stat-number">{confirmed_applications_in_period}</div>
          <p className="stat-caption">${parseFloat(confirmed_tuition_financed_in_period).toLocaleString()} in tuition</p>
        </div>
        <div className="stat">
          <div className="home-stat-title">Signed</div>
          <div className="home-stat-number">{signed_applications_in_period}</div>
          <p className="stat-caption">${parseFloat(signed_tuition_financed_in_period).toLocaleString()} in tuition</p>
        </div>
      </div>

      <hr className="my-6 max-w-[500px]" />

      <div className="home-page-section-title">Loan Portfolio</div>
      <div className="flex stat-row">
        <div className="stat">
          <div className="home-stat-title">Active Loans</div>
          <div className="home-stat-number">{all_loans_count}</div>
          <p className="stat-caption">all-time</p>
        </div>
        <div className="stat">
          <div className="home-stat-title">Total Tuition Funded</div>
          <div className="home-stat-number">${Math.round(parseFloat(all_loans_tuition_financed)).toLocaleString()}</div>
          <p className="stat-caption">all-time</p>
        </div>
        <div className="stat">
          <div className="home-stat-title">Outstanding Balance</div>
          <div className="home-stat-number">${Math.round(parseFloat(all_loans_outstanding_balance)).toLocaleString()}</div>
          <p className="stat-caption">all-time</p>
        </div>
      </div>

      <hr className="my-6 max-w-[500px]" />

      <div className="home-page-section-title">Performance</div>
      <div className="flex stat-row">
        <div className="stat">
          <div className="home-stat-title">On-Time Loans</div>
          <div className="home-stat-number">{current_loans_count}</div>
          <p className="stat-caption">${Math.round(parseFloat(current_loans_outstanding_balance)).toLocaleString()} outstanding</p>
        </div>
        <div className="stat">
          <div className="home-stat-title">Past Due Loans</div>
          <div className="home-stat-number">{past_due_loans_count}</div>
          <p className="stat-caption">${Math.round(parseFloat(past_due_loans_outstanding_balance)).toLocaleString()} outstanding</p>
        </div>
        <div className="stat">
          <div className="home-stat-title">Defaulted Loans</div>
          <div className="home-stat-number">{defaulted_loans_count}</div>
          <p className="stat-caption">${Math.round(parseFloat(defaulted_loans_outstanding_balance)).toLocaleString()} outstanding</p>
        </div>
      </div>
    </div>
  );
};

export default HomeStats;
