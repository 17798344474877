import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LoanApplicationData from '../../models/LoanApplicationData';
import { Snackbar, Button, CircularProgress } from '@mui/material';
import { searchLoanApplicationsAtSchool } from '../../api/applications';
import debounce from 'lodash.debounce';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const activeSettingsIcon = "./sidebarIcons/settings_active.svg"
const inactiveSettingsIcon = "./sidebarIcons/settings_inactive.svg"
const activeContactIcon = "./sidebarIcons/contact_active.svg"
const inactiveContactIcon = "./sidebarIcons/contact_inactive.svg"


const TopBar = ({ activeSchool, selectedTab, onSelectApplication }) => {
    const navigate = useNavigate();
    const isSettingsActive = selectedTab === 'settings';
    const isContactActive = selectedTab === 'contact';
    const [searchQuery, setSearchQuery] = useState('');

    const [searchResults, setSearchResults] = useState(null);
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);

    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const searchInputRef = useRef(null);
    const searchResultsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchInputRef.current &&
                !searchInputRef.current.contains(event.target) &&
                searchResultsRef.current &&
                !searchResultsRef.current.contains(event.target)
            ) {
                setIsSearchResultsVisible(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCopyLink = () => {
        const applicationLink = `https://apply.fortifyedu.com?school=${activeSchool.id}`
        navigator.clipboard.writeText(applicationLink);
        setIsSnackbarOpen(true);
    };

    const handleSettingsClick = () => {
        navigate('/settings');
    };

    const handleContactClick = () => {
        navigate('/contact');
    };

    const fetchAndUpdateSearchData = async (query) => {
        setSearchResults(null);

        if (activeSchool && activeSchool.id && query.length > 0) {
            try {
                const data = await searchLoanApplicationsAtSchool(query, activeSchool.id);
                const matches = data?.matches || [];
                const results = matches.map(result => new LoanApplicationData(result));
                setSearchResults(results);
            } catch (error) {
                logErrorToConsole("Error fetching search data", error);
                logErrorToSentryWithContext(error, {
                  query,
                  schoolId: activeSchool.id,
                });
            }
        }
    };

    const debouncedFetchAndUpdateSearchData = useCallback(debounce(fetchAndUpdateSearchData, 300), [activeSchool]);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setIsSearchResultsVisible(!query ? false : true);
        debouncedFetchAndUpdateSearchData(query);
    };

    const handleSelectApplication = (applicationData, event) => {
        onSelectApplication(applicationData);
        setSearchQuery('');
        setSearchResults(null);
        event.stopPropagation();
    };

    const handleSnackbarClose = () => {
        setIsSnackbarOpen(false);
    };

    return (
        <div className="topbar portal-right-width mt-2 flex items-center justify-between py-4 bg-white">
            <div className="relative flex-grow">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <img src="/search.svg" alt="Search" className="w-5 h-5" />
                </span>
                <input
                    type="text"
                    placeholder="Search for a student..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onBlur={() => {
                        setTimeout(() => {
                            setIsSearchResultsVisible(false);
                            setSearchQuery('');
                            setSearchResults(null);
                        }, 200);
                    }}
                    className="pl-10 p-2 rounded border border-gray-300 hover:bg-gray-100 focus:bg-gray-100 w-full"
                    ref={searchInputRef}
                />

                {searchQuery.length > 0 && isSearchResultsVisible && (
                    <div ref={searchResultsRef}>
                        {searchResults === null ? (
                            <div className="absolute bg-white border border-gray-300 w-full mt-1 rounded shadow-lg z-10 min-h-[100px] flex items-center justify-center text-gray-500">
                                <CircularProgress size={20} />
                            </div>
                        ) : (
                            searchResults.length > 0 ? (
                                <ul className="absolute bg-white border border-gray-300 w-full mt-1 rounded shadow-lg z-10 max-h-60 overflow-y-auto">
                                    {searchResults.map(result => (
                                        <li
                                            key={result.id}
                                            className="p-2 hover:bg-gray-200 cursor-pointer"
                                            onClick={(event) => handleSelectApplication(result, event)}
                                        >
                                            {result.student.account.firstName} {result.student.account.lastName} - {result.program.programName}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="absolute bg-white border border-gray-300 w-full mt-1 rounded shadow-lg z-10 min-h-[100px] flex items-center justify-center text-gray-500">
                                    No results found
                                </div>
                            )
                        )}
                    </div>
                )}

            </div>

            <div className="flex items-center space-x-4 ml-4">
                <button
                    className={`p-2 ${isContactActive ? 'text-fortify-green' : 'text-gray-600'}`}
                    onClick={handleContactClick}
                >
                    <img src={(isContactActive) ? activeContactIcon : inactiveContactIcon} alt="Contact" className="page-icon" />
                </button>
                <button
                    className={`p-2 ${isSettingsActive ? 'text-fortify-green' : 'text-gray-600'}`}
                    onClick={handleSettingsClick}
                >
                    <img src={(isSettingsActive) ? activeSettingsIcon : inactiveSettingsIcon} alt="Settings" className="page-icon" />
                </button>
                <button className="px-3 py-2 bg-fortify-green text-white font-semibold rounded-md flex items-center" onClick={handleCopyLink}>
                    <img src="./url.svg" alt="Application Link" className="w-5 h-5 mr-2" />
                    App Link
                </button>
            </div>
            <Snackbar
                open={isSnackbarOpen}
                onClose={handleSnackbarClose}
                message="Your school's Fortify application link was copied to your clipboard!"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2500}
                action={
                    <Button color="inherit" size="small" onClick={handleSnackbarClose} style={{ padding: '4px', minWidth: 'unset' }}>
                        <img src="./close_white.svg" alt="Close" className="w-5 h-5" />
                    </Button>
                }
                ContentProps={{
                    style: {
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        padding: '12px 24px',
                        fontSize: '1rem',
                    },
                }}
            />
        </div>
    );
};

export default TopBar;