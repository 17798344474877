import React from 'react';

// Icons:
const clockIcon = "/applicationFrameStages/clock.svg"
const successIcon = "/applicationFrameStages/success.svg"
const archiveIcon = "/applicationFrameStages/archive.svg"
const flagIcon = "/applicationFrameStages/flag.svg"
const alertIcon = "/applicationFrameStages/alert.svg"

// Colors
// TODO: these colors look horrid

const orange = "#d29700";
const red = "#dd2222";
const green = "#12543b";
const black = "#000000";
const white = "#ffffff";
const lightGray = "#e9e9e9";
const darkGray = "#7A7A7A"


const StageBox = ({ stage, text }) => {
  let icon, backgroundColor, textColor, borderColor;

  if (stage === "success") {
    icon = successIcon;
    backgroundColor = green;
    borderColor = green;
    textColor = white;
  } else if (stage === "archived") {
    icon = archiveIcon;
    backgroundColor = darkGray;
    borderColor = darkGray;
    textColor = white;
  } else if (stage === "incomplete") {
    icon = clockIcon;
    backgroundColor = lightGray;
    borderColor = lightGray;
    textColor = black;
  } else if (stage === "error") {
    icon = alertIcon;
    backgroundColor = red;
    borderColor = red;
    textColor = white;
  } else if (stage === "actionRequired") {
    icon = flagIcon;
    backgroundColor = orange;
    borderColor = orange;
    textColor = white;
  }

  return (
    <div className="stage-box rounded-lg" style={{ borderColor: borderColor, borderWidth: '1px', borderStyle: 'solid' }}>
      <div className="box flex items-center p-2 rounded-md" style={{ backgroundColor: backgroundColor }}>
        <img src={icon} alt="Stage Icon" className="w-5 h-5 ml-1 mr-2" />
        <div className="text text-[1.08rem]" style={{ color: textColor }}>{text}</div>
      </div>
    </div>
  );
};

export default StageBox;
