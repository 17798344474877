export const formatCurrency = (amount) => {
    if (amount === null) return '';
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

export const formatCurrencyForCSV = (amount) => {
    if (amount === null) return '';
    return amount.toFixed(2);
};

export const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }).format(date);
};

export const formatDateForCSV = (date) => {
    if (!date) return '';
    return new Date(date).toISOString().split('T')[0]; // This will return the date in YYYY-MM-DD format
};

export const formatPercent = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
};

export const formatPhone = (phone) => {
    if (!/^\d{10}$/.test(phone)) return phone;
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};
